/*-----------------------------------------------
|   Chat Page
-----------------------------------------------*/

.card-chat {
  height: calc(100vh - #{$top-nav-height} - #{$responsive-footer-height});
  z-index: 1;
  .card-body {
    position: relative;
    overflow: hidden;
  }
}
.card-chat-pane {
  height: calc(100% - 2.063rem);
  position: relative;
  overflow: hidden;
}
.card-chat-content {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  height: 100%;
  font-size: map-get($font-sizes, '-1');
  position: relative;
  display: flex;
  flex-direction: column;
}
/*-----------------------------------------------
|   Chat Sidebar
-----------------------------------------------*/
.chat-sidebar {
  position: absolute;
  height: 100%;
  z-index: 11;
  top: 0;
  left: -100%;
  width: 100%;
  transition: all 0.5s ease;
  overflow: hidden;
}
.contacts-list {
  height: calc(100% - 2rem);
  border-right: 1px solid var(--#{$prefix}border-color);
  overflow-y: auto;
  width: 100%;
}
.chat-contact {
  position: relative;
  width: 100% !important;
  align-items: center;
  height: 4.188rem;
  cursor: pointer;
  border-top: 1px solid transparent;
  background-color: var(--#{$prefix}chat-contact-bg);
  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0.25rem;
    left: 0;
    top: 0;
  }
  &:first-child {
    border-top: 0 !important;
  }
  @include hover-focus() {
    &:after {
      background-color: var(--#{$prefix}success);
    }
  }
  &.active {
    &:after {
      background-color: var(--#{$prefix}success);
    }
  }
  &.active:not(:first-child),
  &.active + .chat-contact {
    border-top: 1px solid var(--#{$prefix}border-color);
  }

  .hover-actions {
    background-color: var(--#{$prefix}card-bg);
  }
  .hover-actions > * {
    pointer-events: none;
  }
}
.chat-contact-body {
  position: relative;
  min-width: 0;
}
.chat-contact-title {
  font-weight: $font-weight-normal;
}
.message-time {
  color: var(--#{$prefix}gray-400);
}
.chat-contact-content {
  font-size: map-get($font-sizes, "-1");
  color: var(--#{$prefix}gray-600);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unread-message {
  color: var(--#{$prefix}black);
  .chat-contact-title,
  .chat-contact-content {
    font-weight: $font-weight-semi-bold;
    color: var(--#{$prefix}black);
  }
  .message-time {
    color: var(--#{$prefix}primary);
  }
}

// Contacts search style
.contacts-search-wrapper {
  position: relative;
  border-top: 1px solid var(--#{$prefix}border-color);
  border-right: 1px solid var(--#{$prefix}border-color);
  background-color: var(--#{$prefix}chat-contact-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.063rem;
}
.chat-contacts-search {
  padding-left: 2.12rem;
  background-color: var(--#{$prefix}card-bg);
  @include hover-focus {
    box-shadow: none;
  }
}
.contacts-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $gray-400;
  font-size: map-get($font-sizes, "-1");
  left: 1rem;
}

/*-----------------------------------------------
|   Chat contents
-----------------------------------------------*/
.chat-content-header {
  padding: map-get($spacers, 3);
  border-bottom: 1px solid var(--#{$prefix}border-color);
}
.chat-content-body {
  height: calc(100% - 4.25rem);
  .scroll-content {
    position: relative;
  }
}
.chat-content-scroll-area {
  height: 100%;
  padding-bottom: map-get($spacers, 3);
  overflow-y: auto;
}
.chat-message {
  max-width: calc(100% - 7rem);
  margin-bottom: map-get($spacers, 1);
  display: inline-block;
  position: relative;
}
.message-settings {
  position: absolute;
  bottom: -0.9375rem;
  border: 1px solid var(--#{$prefix}gray-200);
  background-color: var(--#{$prefix}gray-100);
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  text-align: center;
}
.message-settings-left {
  right: -0.9375rem;
}
.message-settings-right {
  left: -0.9375rem;
}
.conversation-info {
  position: absolute;
  width: 15rem;
  right: -15rem;
  background-color: var(--#{$prefix}gray-100);
  border-left: 1px solid var(--#{$prefix}border-color);
  z-index: 10;
  top: 4.25rem;
  height: calc(100% - 4.25rem);
  transition: right 0.5s ease;
  &.show {
    right: 0;
  }
  .conversation-info-icon {
    width: 1.25rem;
    font-size: map_get($font-sizes, 0);
  }
}
.chat-file-upload {
  margin-bottom: 0;
  padding: 0 map-get($spacers, 2);
  z-index: 2;
  color: var(--#{$prefix}gray-500);
  @include hover-focus {
    color: var(--#{$prefix}gray-600);
  }
}
.btn-message-submit {
  position: absolute;
  right: 3.938rem;
  top: 0.1875rem;
}
.btn-send {
  z-index: 2;
  padding: 0;
  background-color: transparent;
  color: var(--#{$prefix}gray-500);
  outline: none !important;
  margin-right: map-get($spacers, 3);
}
.chat-editor-area {
  border-top: 1px solid var(--#{$prefix}border-color);
  display: flex;
  align-items: center;
  width: 100%;
  .emoji-icon {
    padding: 0 map-get($spacers, 1);
    margin-right: map-get($spacers, 2);
    cursor: pointer;
    color: var(--#{$prefix}gray-500);
  
    @include hover-focus {
      color: var(--#{$prefix}gray-600) !important;
    }
  }
  
  .emojiarea-editor {
    padding: 0.3rem 1rem;
    flex: 1;
    font-size: 14px;
    max-height: 6.25rem;
    overflow: auto;
    word-break: break-all;
    box-shadow: $input-box-shadow;
    &[contenteditable=true]:empty:before{
      content: attr(placeholder);
      pointer-events: none;
      color: var(--#{$prefix}gray-400);
    }    
  }
  
}
.chat-option {
  color: var(--#{$prefix}gray-400);
  @include hover-focus {
    color: var(--#{$prefix}primary);
  }
}
@include media-breakpoint-up(sm) {
  .contacts-list {
    height: calc(100% - 2.063rem);
  }
  .conversation-scroll-area {
    height: calc(100% - 2.188rem);
  }
  .card-chat {
    height: calc(100vh - #{$top-nav-height} - #{$footer-height});
  }
}
@include media-breakpoint-up(md) {
  .chat-sidebar {
    position: relative;
    height: 100%;
    top: 0;
    left: 0 !important;
    width: auto;
    transition: none;
  }
}
@include media-breakpoint-up(lg) {
  .chat-sidebar {
    flex: 0 0 17.5rem;
    max-width: 17.5rem;
  }
}
@include media-breakpoint-up(xxl) {
  .chat-sidebar {
    flex: 0 0 21.88rem;
    max-width: 21.88rem;
  }
}

// Specific styles for edge
.safari {
  .contacts-list {
    height: calc(100% - 2.063rem);
  }
  .contacts-search-wrapper {
    height: 2.125rem;
  }
}

// Browser support
.ipad,
.mobile.safari,
.mobile.chrome {
  .card-chat {
    height: calc(100vh - #{$top-nav-height} - #{$responsive-footer-height} - 3.5rem);
  }
}
